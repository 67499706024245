import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledCollapse } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import '../../components/Header/Header.css';
import logo from '../../img/Logo.svg';
import { Modal, Row, Col } from 'react-bootstrap'
import Input from '../Inputs/input'
import CustomButton from '../Button/button';
import ResetPasswordImage from '../../../src/img/correct.png';
import { toast } from 'react-toastify';
import { Apiurl, CandidateId, CandidateIdGet, GetToken, loginValidates, getUserEmail, RemoveUserSession, SetToken, userEmail, ValidateResetLink, XApiKey } from '../../utils/utils';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const Headers = () => {
    const [formState, setFormState] = useState({
        loginEmail: '',
        loginPassword: '',
        forgotPasswordEmail: '',
    })

    //login states
    const [loginModal, setLoginModal] = useState(false);
    const closeLoginModal = () => setLoginModal(false);
    const showLoginModal = () => setLoginModal(true);

    // forgot password states
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const closeForgotPassword = () => setForgotPasswordModal(false);
    const showForgotPassword = () => showForgotPassword(true);

    //resend email states
    const [resendEmail, setResendEmail] = useState('');
    const closeResendEmail = () => setResendEmail(false);
    const showResendEmail = () => setResendEmail(true);
    const Emailvalidate = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    const [forgotCaptcha, setForgotCaptcha] = useState();

    const navigate = useNavigate();
    const popup = useLocation();
    const [showModal, setShowModal] = useState(popup.state);

    useEffect(() => {
        if (showModal === "showloginModal") {
            navigate("/");
            showLoginModal();
        }
    }, [])

    const onChange = (event) => {
        const name = event?.target?.name;
        const value = event?.target?.value;
        let localState = { ...formState }
        localState[name] = value
        setFormState({ ...localState })
    }

    const LoginSubmit = () => {
        if (!loginValidates(formState, Emailvalidate)) return;

        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/login?email=${formState.loginEmail}&password=${formState.loginPassword}`
        };
        axios(option)
            .then((e) => {

                if (e?.data?.statusCode === 200) {
                    toast.success("user has been successfully login");
                    SetToken(e?.data?.token);
                    CandidateId(e?.data?.id);
                    closeLoginModal();
                }
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage)
                }

            })
            .catch(() => {

            })
    }

    const Candidateid = CandidateIdGet();
    const Token = GetToken();
    //candidate logout
    const LogoutSubmit = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
                'authorization': `Bearer ${GetToken()}`
            },
            url: `${Apiurl()}/candidate/logout?token=${GetToken()}`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    toast.success(e?.data?.successMessage)
                    RemoveUserSession();
                    window?.location?.reload();
                }
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage);
                }
            })
            .catch(() => {

            })
    }
    //reset link
    const ResetLinkSend = () => {
        if (!ValidateResetLink(formState, Emailvalidate, forgotCaptcha)) return;
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/issuepassword?email=${formState?.forgotPasswordEmail}`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    userEmail(formState?.forgotPasswordEmail);
                    closeForgotPassword();
                    showResendEmail();
                }
            })
            .catch(() => {

            })

    }

    //resend mail
    const ResendEmail = () => {
        const option = {
            method: 'GET', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            url: `${Apiurl()}/candidate/issuepassword?email=${getUserEmail()}`
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    toast.success("reset link sent to your email please check your email");
                }
            })
            .catch(() => {

            })

    }

    return (
        <>

            {/* header section start */}
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/"><img src={logo} className="img-fluid logo" alt="MRN Logo" /></NavbarBrand>
                    <NavbarToggler className="mr-2" id="toggler" />
                    <UncontrolledCollapse className="d-sm-inline-flex flex-sm-row-reverse"
                        toggler="#toggler"
                        navbar
                    >
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} to="/Jobs">Jobs</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/">Training</NavLink>
                            </NavItem>
                            {
                                Candidateid && Token ? <NavItem>
                                    <NavLink className="sign-in" tag={Link} to="/" onClick={() => LogoutSubmit()}>Logout</NavLink>
                                </NavItem> :
                                    <>
                                        <NavItem>
                                            <NavLink className="sign-in" tag={Link} to="/" onClick={showLoginModal}>Sign In</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="sign-up" tag={Link} to="/">Sign Up</NavLink>
                                        </NavItem>
                                    </>
                            }

                        </ul>
                    </UncontrolledCollapse >
                </Navbar>
            </header>
            {/* header section end */}


            {/* login modal start */}
            <Modal show={loginModal} onHide={closeLoginModal} className="login-modal " backdrop="static">
                <Modal.Body className="login-modal-body">
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <h1>Login To Job Portal</h1>
                                        <hr className="hr-login-heading" />

                                        <div className="inputs-main-section">
                                            <Input label="Your email" placeholder="email@address.com" type="email"

                                                onChange={onChange}
                                                value={formState?.loginEmail}
                                                name="loginEmail"
                                            />
                                        </div>

                                        <div className="inputs-main-section">
                                            <Input
                                                label="Password"
                                                placeholder="8+ characters required"
                                                type="password"
                                                onChange={onChange}
                                                value={formState?.loginPassword}
                                                name="loginPassword"

                                            />
                                        </div>

                                        <div className="forgot-password-section">
                                            <span className="forget_pass">
                                                <a onClick={() => { setForgotPasswordModal(true); setLoginModal(false) }}>Forgot Password?</a>
                                            </span>
                                        </div>

                                        <div className="submit-button-section">
                                            <CustomButton text="Sign in" Submit={LoginSubmit} />
                                        </div>

                                        <div className="sign-up-link-section">
                                            <h4>Don't have an account yet? <span><a href="#">Sign up here</a></span></h4>
                                        </div>



                                    </div>

                                </Col>

                            </Row>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            {/* login modal end */}

            {/* forgot password modal start */}
            <Modal show={forgotPasswordModal} onHide={closeForgotPassword} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <h1>Password Reset</h1>
                                        <hr className="hr-login-heading" />

                                        <div className="forgot-password-heading-inner">
                                            <h3>Enter the email address associated with your account and, we'll email you a link to reset your password.</h3>
                                        </div>
                                        <div className="inputs-main-section forgot-input-change">
                                            <Input label="Your email" placeholder="email@address.com" type="email"
                                                onChange={onChange} value={formState?.forgotPasswordEmail} name="forgotPasswordEmail"
                                            />
                                        </div>

                                        <div className="main-section-captcha">

                                            <ReCAPTCHA
                                                sitekey="6Lf1dtAkAAAAAPZUiH82grk2GYeghAtorDYhYr2V"
                                                onChange={(e) => setForgotCaptcha(e)}
                                            />

                                        </div>

                                        <div className="submit-button-section">
                                            <CustomButton text="Send Reset Link" Submit={ResetLinkSend} />
                                        </div>

                                        <div className="sign-up-link-section">
                                            <h4>Remember Your Password? <span><a className="forgot-login-link" onClick={() => { closeForgotPassword(); setLoginModal(true) }}>Log in</a></span></h4>
                                        </div>

                                    </div>

                                </Col>

                            </Row>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            {/* forgot password modal end */}

            {/* resend password modal start */}
            <Modal show={resendEmail} onHide={closeResendEmail} className="login-modal" backdrop="static">
                <Modal.Body className="login-modal-body">
                    <div className="login-modal-header login-modal-card-main-section">
                        <div className="login-card-section">
                            <Row>
                                <Col md={12} className="left-section-login">
                                    <div className="card-login card-login-50">
                                        <h1>Check Your Email</h1>
                                        <hr className="hr-login-heading" />
                                        <div className="reset-password-img-section">
                                            <img src={ResetPasswordImage} alt="correct-img" />
                                        </div>
                                        <div className="forgot-password-heading-inner resend-heading">
                                            <h3>Please check your email address for instructions to reset your password.</h3>
                                        </div>

                                        <div className="submit-button-section">
                                            <CustomButton text="Resend Email" Submit={ResendEmail} />
                                        </div>
                                    </div>

                                </Col>

                            </Row>

                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            {/* resend password modal start */}

        </>
    );
};
export default Headers;