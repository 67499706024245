import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineSearch } from "react-icons/ai";
import '../../components/Inputs/input.css';



const Input = ({ label, placeholder, type, setOnChange, value, asterisk, disable, name, onChange }) => {
    const [passwordVisible, setPasswordVisile] = useState(false);
    return (
        <>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="label-section-inputs mb-0">{label}
                        {
                            asterisk ? <span className="star-mandatory-field">*</span> : null
                        }

                    </Form.Label>
                    <div className="used-icon-password-visible">
                        <Form.Control
                            type={type === 'password' && passwordVisible ? 'text' : type}
                            placeholder={placeholder}
                            disabled={disable}
                            className="inputs-custom"
                            onChange={onChange ? onChange : (e) => setOnChange(e?.target?.value)}
                            value={value}
                            name={name} />
                        {type === 'password' ?
                            passwordVisible ?
                                <AiOutlineEye className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} /> :
                                <AiOutlineEyeInvisible className="icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} />
                            : null}




                    </div>
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
            </Form>
        </>
    )
}
export default Input;