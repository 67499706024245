import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Testimonials.css';

export class Testimonials extends Component {

    render() {
        const state = {
            responsive: {
                0: {
                    items: 1,
                },
                1000: {
                    items: 3,
                },
            },
        }
        return (
            <div>
                <OwlCarousel id="testimonialsScroller" items={3} margin={8} center={true} autoplay={true} autoplayTimeout={5000} loop={true} dots={true} responsive={state.responsive} >
                    <div >
                        <img className="img" src={'assets/images/testimonials/testimonial-avatar.png'} width="300" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42"/>
                        <h4>Featured Candidate</h4>
                        <p>To say that starting to work in the region has been life-changing is an understatement. My career is back on track and I got my life back.</p>
                    </div>
                    <div >
                        <img className="img" src={'assets/images/testimonials/testimonial-avatar.png'} width="300" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42" />
                        <h4>Featured Candidate</h4>
                        <p>To say that starting to work in the region has been life-changing is an understatement. My career is back on track and I got my life back.</p>
                    </div>
                    <div >
                        <img className="img" src={'assets/images/testimonials/testimonial-avatar.png'} width="300" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42" />
                        <h4>Featured Candidate</h4>
                        <p>To say that starting to work in the region has been life-changing is an understatement. My career is back on track and I got my life back.</p>
                    </div>
                    <div >
                        <img className="img" src={'assets/images/testimonials/testimonial-avatar.png'} width="300" />
                        <img className="img" src={'assets/images/testimonials/quote.svg'} width="42" height="42" />
                        <h4>Featured Candidate</h4>
                        <p>To say that starting to work in the region has been life-changing is an understatement. My career is back on track and I got my life back.</p>
                    </div>
                </OwlCarousel>
            </div>
        )
    }
}


export default Testimonials