import { toast } from 'react-toastify';

//api url
export const Apiurl = () => {
    return 'https://test.core.mnronline.com/api';
} 

//login token set
export const SetToken = (token) => {
    localStorage.setItem('@token', token)
}

//login token get
export const GetToken = () => {
    return localStorage.getItem('@token') || null;
}

//candidate id set
export const CandidateId = (candidateid) => {
    localStorage.setItem('candidateid', candidateid);
}

//candidate id get
export const CandidateIdGet = () => {
    return localStorage.getItem('candidateid') || null;
}

//remove candidate id
export const RemoveUserSession = () => {
    localStorage.removeItem('candidateid');
    localStorage.removeItem('@token');
} 

//store XApiKey
export const XApiKey = () => {
    return 'pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp';
}

//password token set
export const PasswordtokenSet = (passwordToken) => {
    localStorage.setItem('@passwordToken', passwordToken) 
}

//password token get
export const PasswordtokenGet = () => {
   return localStorage.getItem('@passwordToken') || null;
}


//store userEmail
export const userEmail = (useremail) => {
    localStorage.setItem('useremail', useremail)
}

//get useremail
export const getUserEmail = () => {
    return localStorage.getItem('useremail') || null;
}



export const loginValidates = (formState, Emailvalidate) => {
    if (!formState.loginEmail) {
        toast.error("Please Enter Your Email Address")
        return false
    }
    if (!Emailvalidate.test(formState.loginEmail)) {
        toast.error("Please Enter Valid Email Address")
        return false
    }
    if (!formState.loginPassword) {
        toast.error("Please Enter Your Password")
        return false
    }
    if (formState.loginPassword.length < 8) {
        toast.error("Password must be at least 8 characters ")
        return false
    }
    return true
}

export const ValidateResetLink = (formState, Emailvalidate, forgotCaptcha) => {
    if (!formState.forgotPasswordEmail) {
        toast.error("Please Enter Your Email Address")
        return false
    }
    if (!Emailvalidate.test(formState.forgotPasswordEmail)) {
        toast.error("Please Enter Valid Email Address")
        return false
    }
    if (!forgotCaptcha) {
        toast.error("Please Select Captcha")
        return false
    }
    return true

}

