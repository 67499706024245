import React, { Component } from 'react';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import HeroGraphic from '../img/hero-graphic.svg';
import ApplicantGuidance from '../img/applicant-guidance.svg';
import ImportantInfo from '../img/important-info.svg';
import LogoScroller from '../components/LogoScroller/LogoScroller';
import Testimonials from '../components/Testimonials/Testimonials';

//export class Home extends Component {
//  static displayName = Home.name;

//    render() {
const Home = () => {
    return (
        <div>
            <div id="hero" className="alternative-section mb-5">
                <Container>
                    <Row className="flex-lg-row-reverse align-items-center g-3 py-5">
                        <Col className="col-10 col-sm-8 col-lg-6 pb-4">
                            <img src={HeroGraphic} className="d-block img-fluid" alt="MNR Job Portal" loading="lazy" />
                        </Col>
                        <Col className="col-lg-6">
                            <h1 className="mb-5">Employment and learning portal for the Kurdistan Region's energy sector</h1>
                            <h2 className="lead mb-5">Search for Jobs, Internship or Training opportunities.</h2>
                            <Col className="field-wrapper">
                                <span className="search-field-icon">
                                    <Input id="jobSearchField" className="mb-3" name="search" placeholder="Job Title, Keywords..." type="search" />
                                </span>
                            </Col>
                            <Button className="btn mb-5">Search</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="mb-5 pb-4">
                <Container>
                    <Row className="g-3">
                        <Col className="col-lg-12">
                            <h2 className="mb-5 pt-4">Sectors Covered</h2>
                        </Col>
                    </Row>
                    <Row >
                        <Col className="col-12 col-lg-6">
                            <h3 className="mb-4">Oil &amp; Gas</h3>
                            <ul className="industries oil-gas mb-5">
                                <li>Exploration &amp; Production</li>
                                <li>Equipment &amp; Services</li>
                                <li>Pipeline &amp; Refining</li>
                            </ul>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <h3 className="mb-4">Power</h3>
                            <ul className="industries power mb-5">
                                <li>Renewable Energy</li>
                                <li>Conventional Energy</li>
                                <li>Transmission &amp; Distribution</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="guidance" className="alternative-section">
                <Container>
                    <Row className="g-3 py-5">
                        <Col className="col-lg-12">
                            <h2 className="mb-3 pt-4">Applicant Guidance</h2>
                        </Col>
                    </Row>
                    <Row className="flex-lg-row-reverse">
                        <Col className="col-12 col-lg-6 mb-5">
                            <img src={ApplicantGuidance} className="d-block img-fluid" alt="MNR Job Portal - Guidance and rules" loading="lazy" />
                        </Col>
                        <Col className="col-12 col-lg-6 mb-5">
                            <h3 className="mb-4">Honesty</h3>
                            <p className="mb-5">Be honest on your CV and application. Dishonest applications will be found out in the verification process.</p>
                            <h3 className="mb-4">Accuracy</h3>
                            <p className="mb-5">Ensure the information you provide is accurate, up-to-date and complete. Incomplete applications will not be verified.</p>
                            <h3 className="mb-4">Suitability</h3>
                            <p className="mb-5">Employers receive many applications. To avoid getting lost in the crowd, we ask that you apply for roles where you meet the requirements.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="companyLogos" class="pt-5 pb-5 mt-5 mb-5">
                <Container>
                    <LogoScroller />
                </Container>
            </div>
            <div id="hiring" className="alternative-section pb-3">
                <Container>
                    <Row className="g-3 py-5">
                        <Col className="col-lg-12">
                            <h2 className="mb-3 pt-4 text-start">Hiring Process</h2>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="col-12 mb-5">
                            <Row className="hiring-process-wrapper">
                                <Col className="col-12 col-lg-2">
                                    <h4>Candidate Registration</h4>
                                    <img src={require('../img/registration.svg').default} className="d-block mx-auto img-fluid" alt="Candidate Registration" width="146" loading="lazy" />
                                    <p>Candidate registers on the Job Portal and completes their profile</p>
                                </Col>
                                <Col className="col-12 col-lg-3 align-self-center">
                                    <img src={require('../img/arrow.svg').default} className="d-none d-sm-block mx-auto img-fluid arrow-right-up" width="165" loading="lazy" />
                                    <img src={require('../img/mobile-arrow.svg').default} className="d-block d-sm-none mx-auto img-fluid" width="46" loading="lazy" />
                                </Col>
                                <Col className="col-12 col-lg-2">
                                    <h4>Candidate Verification</h4>
                                    <img src={require('../img/verification.svg').default} className="d-block mx-auto img-fluid" alt="Candidate Registration" width="146" loading="lazy" />
                                    <p>Job Portal agents screen and verify candidate's profile</p>
                                </Col>
                                <Col className="col-12 col-lg-3 align-self-center">
                                    <img src={require('../img/arrow.svg').default} className="d-none d-sm-block mx-auto img-fluid arrow-right-down" width="165" loading="lazy" />
                                    <img src={require('../img/mobile-arrow.svg').default} className="d-block d-sm-none mx-auto img-fluid" width="46" loading="lazy" />
                                </Col>
                                <Col className="col-12 col-lg-2">
                                    <h4>Job Application</h4>
                                    <img src={require('../img/job-application.svg').default} className="d-block mx-auto img-fluid" alt="Candidate Registration" width="146" loading="lazy" />
                                    <p>Candidate applies for suitable jobs</p>
                                </Col>
                                <Col className="col-12 col-lg-3 align-self-center d-block d-sm-none extra-arrow">
                                    <img src={require('../img/mobile-arrow.svg').default} className="mx-auto img-fluid" width="46" loading="lazy" />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-12 mb-5">
                            <Row>
                                <Col className="col-12 col-lg-2">
                                    <h4>Candidate Hired</h4>
                                    <img src={require('../img/selection.svg').default} className="d-block mx-auto img-fluid" alt="Candidate Registration" width="146" loading="lazy" />
                                    <p>Candidate accepts the job offer</p>
                                </Col>
                                <Col className="col-12 col-lg-3 align-self-center">
                                    <img src={require('../img/arrow.svg').default} className="d-none d-sm-block mx-auto img-fluid arrow-left-up" width="165" loading="lazy" />
                                    <img src={require('../img/mobile-arrow.svg').default} className="d-block d-sm-none mx-auto img-fluid" width="46" loading="lazy" />
                                </Col>
                                <Col className="col-12 col-lg-2">
                                    <h4>Job Offer</h4>
                                    <img src={require('../img/offer.svg').default} className="d-block mx-auto img-fluid" alt="Candidate Registration" width="146" loading="lazy" />
                                    <p>Employer makes a job offer to the candidate</p>
                                </Col>
                                <Col className="col-12 col-lg-3 align-self-center">
                                    <img src={require('../img/arrow.svg').default} className="d-none d-sm-block mx-auto img-fluid arrow-left-down" width="165" loading="lazy" />
                                    <img src={require('../img/mobile-arrow.svg').default} className="d-block d-sm-none mx-auto img-fluid" width="46" loading="lazy" />
                                </Col>
                                <Col className="col-12 col-lg-2">
                                    <h4>Candidate Selection</h4>
                                    <img src={require('../img/hired.svg').default} className="d-block mx-auto img-fluid" alt="Candidate Registration" width="146" loading="lazy" />
                                    <p>Employer selects candidate to participate in their recruitment process</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="importantInfo">
                <Container>
                    <Row className="g-3 py-5">
                        <Col className="col-lg-12">
                            <h2 className="mb-4 pt-4">Important Information</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-5 mb-5">
                            <img src={ImportantInfo} className="d-block img-fluid" alt="MNR Job Portal - Guidance and rules" loading="lazy" />
                        </Col>
                        <Col className="col-12 col-lg-7 mb-5">
                            <ul className="list-group">
                                <li className="list-group-item">The MNR is not responsible for the hiring of staff in the private sector, nor is it involved in the hiring process.</li>
                                <li className="list-group-item">The MNR has established requirements for hiring in its Recruitment Instructions, which companies in the energy sector are required to incorporate in their internal processes.</li>
                                <li className="list-group-item">Employers are responsible for following all applicable laws and regulations, set out by the MNR, Ministry of Labour, or any other authorised Government entity.</li>
                                <li className="list-group-item">If candidates have reason to believe an employer is non-complaint to any applicable law or regulation, a non-compliance report can be raised by completing this form.</li>
                                <li className="list-group-item">Candidates should not raise non-compliance reports because of a failed job application. Non-compliance reports should be raised if a clear breach of law or regulation has been observed.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="testimonials" className="alternative-section pt-5 pb-5">
                <Container>
                    <Testimonials />
                </Container>
            </div>
        </div>
    );
}
export default Home;