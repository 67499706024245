import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import Headers from './Header/Header'
import Home from '../pages/Home';
import Jobs from '../pages/Jobs';
import { Routes, Route } from 'react-router-dom';
//export class Layout extends Component {
//  static displayName = Layout.name;

//  render() {

const Layout = (props) => {
    return (
        <div>
            <Headers />
            {/*<Container fluid tag="main">*/}
            {/*    */}{/*{props.children}*/}
                
            {/*</Container>*/}
            <Home />
            <Footer />
            
        </div>
    );
}
export default Layout;
