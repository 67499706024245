import React, { Component } from 'react';
import {
    Container, Row, Col, Button, Form, Label, Input, FormGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardColumns,
    Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Pagination, PaginationItem, PaginationLink 
} from 'reactstrap';
import { Link } from 'react-router-dom';

//export class Jobs extends Component {
//    static displayName = Jobs.name;

//    render() {
const Jobs = () => {
    return (
        <div>
            <div id="jobsIntro">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <h1>Explore Opportunities</h1>
                            <p>Stakeholders are looking for passionate, self-motivated and skilled individuals to join their teams.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobSearchWidget" className="alternative-section">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Form className="job-search">
                                <Row className="row-cols-lg-4 g-4 align-items-center">
                                    <Col className="field-wrapper">
                                        <span className="search-field-icon">
                                            <Input id="jobSearchField" name="search" placeholder="Job Title, Keywords..." type="search" />
                                        </span>
                                    </Col>
                                    <Col className="field-wrapper">
                                        <span className="cities-field-icon">
                                            <FormGroup>
                                                <Input id="jobCitiesField" name="cities" type="select">
                                                    <option selected disabled>City Or Postcode</option>
                                                    <option>Tawke PSC</option>
                                                    <option>Mosul</option>
                                                    <option>Samarra</option>
                                                    <option>Ramadi</option>
                                                    <option>Karbala</option>
                                                    <option>Basrah</option>
                                                </Input>
                                            </FormGroup>
                                        </span>
                                    </Col>
                                    <Col className="field-wrapper">
                                        <span className="job-family-field-icon">
                                            <FormGroup>
                                                <Input id="jobFamilyField" name="family" type="select">
                                                    <option selected disabled>Job Family</option>
                                                    <option>Management</option>
                                                    <option>Construction</option>
                                                    <option>IT</option>
                                                    <option>HR</option>
                                                </Input>
                                            </FormGroup>
                                        </span>
                                    </Col>
                                    <Col className="field-wrapper d-flex justify-content-md-end align-middle">
                                        <UncontrolledDropdown className="nav-mega">
                                            <div className="dropdown">
                                                <DropdownToggle className="advanced-search d-flex justify-content-center align-items-center" data-toggle="dropdown" tag="span">Advanced</DropdownToggle>
                                                <DropdownMenu className="mega-menu">
                                                    <Row>
                                                        <Col className="col-12 col-md-6">
                                                            <FormGroup>
                                                                <Label for="dateField">Application Deadline</Label>
                                                                <Input id="dateField" name="date" placeholder="Today" type="date" />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="yearsOfExperienceJob">Minimum Years of Experience required in the Job Family</Label>
                                                                <Input id="yearsOfExperienceJob" name="experience" type="select">
                                                                    <option selected disabled>--- Select Experience In Job Family ---</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                </Input>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="employmentType">Employment Type</Label>
                                                                <Input id="employmentType" name="employment" type="select">
                                                                    <option selected disabled>--- Select Employment Type ---</option>
                                                                    <option>Management</option>
                                                                    <option>Construction</option>
                                                                    <option>IT</option>
                                                                    <option>HR</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="col-12 col-md-6">
                                                            <FormGroup>
                                                                <Label for="seniorityLevel">Seniority Level</Label>
                                                                <Input id="seniorityLevel" name="seniority" type="select">
                                                                    <option selected disabled>--- Select Seniority Level ---</option>
                                                                    <option>Junior</option>
                                                                    <option>Mid-weight</option>
                                                                    <option>Senior</option>
                                                                </Input>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label for="yearsOfExperienceOilIndustry">Minimum Years of Experience required in the Oil Industry</Label>
                                                                <Input id="yearsOfExperienceOilIndustry" name="experienceOil" type="select">
                                                                    <option selected disabled>--- Select Experience In Oil Industry ---</option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </DropdownMenu>
                                            </div>
                                        </UncontrolledDropdown>
                                        <Button className="find-jobs-btn"> Find Jobs </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobsFilter">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-8 order-md-first order-last">
                            <p>Showing 1 – 10 of 72 results</p>
                        </Col>
                        <Col className="col-12 col-md-2">
                            <FormGroup>
                                <Input id="jobSort" name="jobsort" type="select">
                                    <option selected disabled>Sort by (Default)</option>
                                    <option>Newest</option>
                                    <option>Oldest</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col className="col-12 col-md-2">
                            <FormGroup>
                                <Input id="yearsOfExperienceOilIndustry" name="experienceOil" type="select">
                                    <option selected disabled>10 per page</option>
                                    <option>20 per page</option>
                                    <option>30 per page</option>
                                    <option>40 per page</option>
                                    <option>50 per page</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobsList">
                <Container>
                    <Row>
                        <Col className="col-12">
                            <CardColumns>
                                <Card className="odd">
                                    <CardBody>
                                        <CardImg alt="Card image cap" src={'assets/images/logos/dno.svg'} width="145" />
                                        <CardTitle tag="h3">PP Construction Project Manager
                                            <span className="featured">Featured</span>
                                            <span className="badge green align-middle">Full time</span>
                                            <span className="badge gold align-middle">Urgent</span>
                                        </CardTitle>
                                        <div className="card-text">
                                            <span className="job-family">Projects, Development & Construction Management</span>
                                            <span className="job-location">Tawke PSC</span>
                                            <span className="job-date-added">December 20, 2021</span>
                                            <span className="job-salary">£80,000 - £100,000 / year</span>
                                            <div className="job-links d-inline">
                                                <span className="job-view">
                                                    <Link to="/jobDetails">View</Link>
                                                </span>
                                                <span className="job-shortlist ms-md-5">
                                                    <Link to="/jobDetails">Shortlist</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card className="even">
                                    <CardBody>
                                        <CardImg alt="Card image cap" src={'assets/images/logos/danagas.svg'} width="145" />
                                        <CardTitle tag="h3">PP Project Manager
                                            <span className="featured">Featured</span>
                                            <span className="badge green align-middle">Full time</span>
                                            <span className="badge gold align-middle">Urgent</span>
                                        </CardTitle>
                                        <div className="card-text">
                                            <span className="job-family">Projects, Development & Construction Management</span>
                                            <span className="job-location">Tawke PSC</span>
                                            <span className="job-date-added">December 20, 2021</span>
                                            <span className="job-salary">£80,000 - £100,000 / year</span>
                                            <div className="job-links d-inline">
                                                <span className="job-view">
                                                    <Link to="/jobDetails">View</Link>
                                                </span>
                                                <span className="job-shortlist ms-md-5">
                                                    <Link to="/jobDetails">Shortlist</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card className="odd">
                                    <CardBody>
                                        <CardImg alt="Card image cap" src={'assets/images/logos/gasprom.svg'} width="145" />
                                        <CardTitle tag="h3">Senior Accountant
                                            <span className="featured">Featured</span>
                                            <span className="badge green align-middle">Full time</span>
                                            <span className="badge gold align-middle">Urgent</span>
                                        </CardTitle>
                                        <div className="card-text">
                                            <span className="job-family">Projects, Development & Construction Management</span>
                                            <span className="job-location">Tawke PSC</span>
                                            <span className="job-date-added">December 20, 2021</span>
                                            <span className="job-salary">£80,000 - £100,000 / year</span>
                                            <div className="job-links d-inline">
                                                <span className="job-view">
                                                    <Link to="/jobDetails">View</Link>
                                                </span>
                                                <span className="job-shortlist ms-md-5">
                                                    <Link to="/jobDetails">Shortlist</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card className="even">
                                    <CardBody>
                                        <CardImg alt="Card image cap" src={'assets/images/logos/hkn.svg'} width="145" />
                                        <CardTitle tag="h3">Web Developer
                                            <span className="featured">Featured</span>
                                            <span className="badge green align-middle">Full time</span>
                                            <span className="badge gold align-middle">Urgent</span>
                                        </CardTitle>
                                        <div className="card-text">
                                            <span className="job-family">Projects, Development & Construction Management</span>
                                            <span className="job-location">Tawke PSC</span>
                                            <span className="job-date-added">December 20, 2021</span>
                                            <span className="job-salary">£80,000 - £100,000 / year</span>
                                            <div className="job-links d-inline">
                                                <span className="job-view">
                                                    <Link to="/jobDetails">View</Link>
                                                </span>
                                                <span className="job-shortlist ms-md-5">
                                                    <Link to="/jobDetails">Shortlist</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card className="odd">
                                    <CardBody>
                                        <CardImg alt="Card image cap" src={'assets/images/logos/taqa.svg'} width="145" />
                                        <CardTitle tag="h3">General Manager
                                            <span className="featured">Featured</span>
                                            <span className="badge green align-middle">Full time</span>
                                            <span className="badge gold align-middle">Urgent</span>
                                        </CardTitle>
                                        <div className="card-text">
                                            <span className="job-family">Projects, Development & Construction Management</span>
                                            <span className="job-location">Tawke PSC</span>
                                            <span className="job-date-added">December 20, 2021</span>
                                            <span className="job-salary">£80,000 - £100,000 / year</span>
                                            <div className="job-links d-inline">
                                                <span className="job-view">
                                                    <Link to="/jobDetails">View</Link>
                                                </span>
                                                <span className="job-shortlist ms-md-5">
                                                    <Link to="/jobDetails">Shortlist</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card className="even">
                                    <CardBody>
                                        <CardImg alt="Card image cap" src={'assets/images/logos/k.svg'} width="145" />
                                        <CardTitle tag="h3">PP Instrument and Controls Project Engineer
                                            <span className="featured">Featured</span>
                                            <span className="badge green align-middle">Full time</span>
                                            <span className="badge gold align-middle">Urgent</span>
                                        </CardTitle>
                                        <div className="card-text">
                                            <span className="job-family">Projects, Development & Construction Management</span>
                                            <span className="job-location">Tawke PSC</span>
                                            <span className="job-date-added">December 20, 2021</span>
                                            <span className="job-salary">£80,000 - £100,000 / year</span>
                                            <div className="job-links d-inline">
                                                <span className="job-view">
                                                    <Link to="/jobDetails">View</Link>
                                                </span>
                                                <span className="job-shortlist ms-md-5">
                                                    <Link to="/jobDetails">Shortlist</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardColumns>
                        </Col>
                    </Row>
                    <Pagination className="mt-2 mb-5">
                        <PaginationItem>
                            <PaginationLink first href="#" />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#" previous />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#">1</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#">2</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#" next />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#" last />
                        </PaginationItem>
                    </Pagination>
                </Container>
            </div>
        </div>
    );
}

export default Jobs;
