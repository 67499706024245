import React, { useState } from 'react';
import Headers from '../../../src/components/Header/Header';
import { Container, Row, Col, Form, Label, Input, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { XApiKey, CandidateIdGet, PasswordtokenGet, Apiurl } from '../../utils/utils';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const PasswordValidate = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^?&\*])/;
    const [passwordVisible, setPasswordVisile] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const ValidateFields = () => {
        if (!password) {
            toast.error("Please Enter Password")
            return false;
        }
        if (password?.length < 8) {
            toast.error("Password must be at least 8 characters ")
            return false;
        }
        if (!confirmPassword) {
            toast.error("Please Enter Confirm Password")
            return false;
        }
        if (confirmPassword?.length < 8) {
            toast.error("Confirm Password must be at least 8 characters ")
            return false;
        }
        if (password != confirmPassword) {
            toast.error("Password do not match")
            return false;
        }
        if (!PasswordValidate.test(password)) {
            toast.error("The password must include lowercase letters, uppercase letters, numbers and special characters @$!%*#.?&^")
            return false;
        }
        return true;
    }

    const ResetPasswordSubmit = (e) => {
        e.preventDefault();
        if (!ValidateFields()) return;

        const data = {};
        data.CandidateId = CandidateIdGet();
        data.Password = password;
        data.PasswordToken = PasswordtokenGet();
        const option = {
            method: 'PUT', headers: {
                'access-control-allow-origin': '*',
                'content-type': 'application/json',
                'XApiKey': `${XApiKey()}`,
            },
            data: JSON.stringify(data),
            url: `${Apiurl()}/candidate/setpassword`,
        };
        axios(option)
            .then((e) => {
                if (e?.data?.statusCode === 200) {
                    toast.success(e?.data?.errorMessage);
                    navigate('/');
                }
                if (e?.data?.statusCode === 500) {
                    toast.error(e?.data?.errorMessage);
                }
            })
            .catch(() => {

            })
    }
    return (
        <div className="main-section-reset-password">
            <Headers />

            <div>
                <div id="applicationIntro">
                    <Container>
                        <Row>
                            <Col className="col-sm-12">
                                <h1>Reset Password</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="applicationForm">
                    <Container>
                        <Row>
                            <Col className="col-sm-12">
                                <Form className="application-form" onSubmit={ResetPasswordSubmit}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="Password Title">Password</Label>
                                                <div className="pssword-inp-reset-screens">
                                                    <Input id="Password Title" name="password" placeholder="Enter Password" type={passwordVisible ? "text" : "password"} onChange={(e) => setPassword(e?.target?.value)} value={password} />

                                                    {
                                                        passwordVisible ?
                                                            <AiOutlineEye className="reset-icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} /> :
                                                            <AiOutlineEyeInvisible className="reset-icon-password-visible" onClick={() => setPasswordVisile(!passwordVisible)} />
                                                    }

                                                </div>

                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="confirm Password Title">Confirm Password</Label>
                                                <div className="pssword-inp-reset-screens">
                                                    <Input id="confirm Password Title" name="confirm Password Title" placeholder="Enter Confirm Password" type={confirmPasswordVisible ? "text" : "password"} onChange={(e) => setConfirmPassword(e?.target?.value)} value={confirmPassword} />
                                                    {
                                                        confirmPasswordVisible ?
                                                            <AiOutlineEye className="reset-icon-password-visible" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} /> :
                                                            <AiOutlineEyeInvisible className="reset-icon-password-visible" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} />
                                                    }
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col md={12}>
                                            <button className="btn btn-primary submit" type="submit">Save & Continue</button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            {/*<Footer />*/}
        </div>
    )
}
export default ResetPassword;