import React, { Component } from 'react';
import { Container, Row, Col, Button, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, List } from 'reactstrap';
import { Link } from 'react-router-dom';
import SimilarJobs from '../components/SimilarJobs/SimilarJobs';

//export class JobDetails extends Component {
//    static displayName = JobDetails.name;

//    render() {

const JobDetails = () => {
    return (
        <div>
            <div id="jobDetailsIntro" className="alternative-section">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Card>
                                <CardBody>
                                    <CardImg alt="Card image cap" src={'assets/images/logos/dno.svg'} width="145" />
                                    <CardTitle tag="h3">PP Construction Project Manager</CardTitle>
                                    <div className="card-text">
                                        <span className="job-family">Projects, Development & Construction Management</span>
                                        <span className="job-location">Tawke PSC</span>
                                        <span className="job-date-added">December 20, 2021</span>
                                        <span className="job-salary">£80,000 - £100,000 / year</span>
                                        <Link className="btn btn-primary apply" to="/Application">Apply Now</Link>
                                        <div class="badge-wrapper">
                                            <span className="badge green align-middle">Full time</span>
                                            <span className="badge gold align-middle">Urgent</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="jobDetails" class="mt-5 mb-5">
                <Container>
                    <Row>
                        <Col className="col-12 col-md-8">
                            <h3>Role in the Organisation:</h3>
                            <ul className="list-group mb-4">
                                <li>
                                    Provide Management during the execution phases of all Brownfield Facilities Projects and Scopes
                                </li>
                                <li>
                                    Application position reports to: Infrastructure Project Manager
                                </li>
                                <li>
                                    No. of personnel (incl. local) in this role: 1
                                </li>
                                <li>
                                    Location & working arrangement: TWK PSC
                                </li>
                                <li>
                                    Roles directly reporting to this position (including local): 0
                                </li>
                            </ul>

                            <h3>Performance Expectations</h3>
                            <p>Project Delivery within the required schedule and approved Budget.</p>

                            <h3>Competency (Knowledge & Skills) Required</h3>
                            <p>10 years + Brownfield Project Management Experience within the MENA region.</p>

                            <h3>Education and Qualifications Expected / Required</h3>
                            <p>Engineering Degree from a recognised University</p>

                            <h3>Experience Expected / Required</h3>
                            <p>10 years + Brownfield Project Management Experience</p>

                            <h3>Other Relevant Competency Requirements:</h3>
                            <ul className="list-group mb-4">
                                <li>
                                    Preparation of Project Estimates and AFE’s
                                </li>
                                <li>
                                    Project Cost control and spend forecasting
                                </li>
                                <li>
                                    Project Schedule development and expediting
                                </li>
                                <li>
                                    Project Scope of work preparation and Contract pre-award management
                                </li>
                                <li>
                                    Post Contract award management
                                </li>
                                <li>
                                    Risk Mitigation and departmental interface management
                                </li>
                            </ul>

                        </Col>
                        <Col className="col-12 col-md-4">
                            <div className="side-bar mb-4">
                                <div className="job-detail performance">
                                    <h4>Performance Expectations</h4>
                                    <p>Project Delivery within the required schedule and approved Budget.</p>
                                </div>

                                <div className="job-detail job-family">
                                    <h4>Job Family</h4>
                                    <p>Human Resources and Training</p>
                                </div>

                                <div className="job-detail job-title">
                                    <h4>Standard Job Title</h4>
                                    <p>HR Manager</p>
                                </div>

                                <div className="job-detail job-level">
                                    <h4>Seniority Level</h4>
                                    <p>Snr Manager</p>
                                </div>

                                <div className="job-detail job-experience">
                                    <h4>Minimum Years of Experience required in the Job Family</h4>
                                    <p>6-9</p>
                                </div>

                                <div className="job-detail industry-experience">
                                    <h4>Minimum Years of Experience required in the Oil Industry</h4>
                                    <p>6-9</p>
                                </div>

                                <div className="job-detail job-type">
                                    <h4>Employment Type</h4>
                                    <p>full-time</p>
                                </div>

                                <div className="job-detail location">
                                    <h4>Location</h4>
                                    <p>Iraq</p>
                                </div>

                                <div className="job-detail city">
                                    <h4>City</h4>
                                    <p>Amedi</p>
                                </div>

                                <div className="job-detail compensation">
                                    <h4>Compensation</h4>
                                    <p>£80,000 - £100,000 / year</p>
                                </div>

                                <div className="job-detail reporting">
                                    <h4>Reporting To</h4>
                                    <p>CEO</p>
                                </div>

                            </div>
                            <div className="side-bar employer mb-4">
                                <img src={'assets/images/logos/dno.svg'} width="145" />
                                <h4>EMPLOYER <span>DNO ASA</span></h4>
                                <ul>
                                    <li>Categories: <span>oil and gas operator</span></li>
                                    <li>Founded Date: <span>1971</span></li>
                                    <li>Location: <span>Norway</span></li>
                                    <li> Phone Number: <span>+44 291 455388</span></li>
                                    <li>Email: <span><a href="mailto:jobs@dno.com">jobs@dno.com</a></span></li>
                                </ul>
                                <Link className="btn btn-primary apply" to="/jobs">View Company Profile</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="similar-jobs alternative-section pt-5 pb-5">
                <Container>
                    <SimilarJobs />
                </Container>
            </div>
        </div>
    );
}
export default JobDetails;
        