import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import ResetPassword from './pages/resetPassword/resetpassword';
import { ToastContainer } from 'react-toastify';
import Jobs from '../src/pages/Jobs'
import JobDetails from './pages/JobDetails';
import Application from './pages/Application';
//import JobListing from './pages/JobListing/JobListing';
import Password from '../src/components/password';
function App() {
    return (
        <>
            <div className="App">

                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <Routes>
                    <Route path='/' element={<Layout />}></Route>
                    <Route path='/Jobs' element={<Jobs />}></Route>
                    <Route path='/resetpassword' element={<ResetPassword />} />
                    <Route path='/jobDetails' element={<JobDetails />} />
                    <Route path='/Application' element={<Application />} />
                    {/*<Route path='/jobListing' element={<JobListing />} />*/}
                    <Route path='/Password' element={<Password />} />
                </Routes>
            </div>
        </>
    );
}
export default App;