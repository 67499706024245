import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './LogoScroller.css';

export class LogoScroller extends Component {

    render() {
        const state = {
            responsive: {
                0: {
                    items: 1,
                },
                1000: {
                    items: 4,
                },
            },
        }
        return (
            <div>
                <OwlCarousel id="logosScroller"
                    items={4}
                    margin={8}
                    nav={true}
                    navText={[
                        '<span class="arrow prev">‹</span>',
                        '<span class="arrow next">›</span>']}
                    autoplay={true}
                    autoplayTimeout={5000}
                    loop={true}
                    responsive={state.responsive} >

                    <div className="card">
                        <img className="img" src={'assets/images/logos/dno.svg'} width="145"  />
                    </div>
                    <div className="card">
                        <img className="img" src={'assets/images/logos/danagas.svg'} width="145" />
                    </div>
                    <div className="card">
                        <img className="img" src={'assets/images/logos/gasprom.svg'} width="145" />
                    </div>
                    <div className="card">
                        <img className="img" src={'assets/images/logos/hkn.svg'} width="145" />
                    </div>
                    <div className="card">
                        <img className="img" src={'assets/images/logos/k.svg'} width="145" />
                    </div>
                    <div className="card">
                        <img className="img" src={'assets/images/logos/rosneft.svg'} width="145" />
                    </div>
                    <div className="card">
                        <img className="img" src={'assets/images/logos/taqa.svg'} width="145" />
                    </div>
                </OwlCarousel>
            </div>
        )
    }
}


export default LogoScroller