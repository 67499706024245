import React from 'react';
import { Container, Row, Col, Form, Label, Input, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';

//export class Application extends Component {
//    static displayName = Application.name;

//    render() {
const Application = () => {
    return (
        <div>
            <div id="applicationIntro">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <h1>Personal details</h1>
                            <p>We'll need these details in order to be able to contact you.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div id="applicationForm">
                <Container>
                    <Row>
                        <Col className="col-sm-12">
                            <Form className="application-form">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="applicantTitle">Email</Label>
                                            <Input id="applicantTitle" name="title" placeholder="with a placeholder" type="email" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="applicantFirstName">First Name</Label>
                                            <Input id="applicantFirstName" name="firstname" placeholder="Enter First name" type="text" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="applicantLastName">Surname</Label>
                                            <Input id="applicantLastName" name="surname" placeholder="Enter Surname" type="text" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="applicantEmail">Email</Label>
                                            <Input id="applicantEmail" name="email" placeholder="your-email@example.com" type="email" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="applicantPhone">Phone</Label>
                                            <Input id="applicantPhone" name="phone" placeholder="### ### ####" type="tel" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="applicantLinkedin">LinkedIn</Label>
                                            <Input id="applicantLinkedIn" name="linkedIn" placeholder="www.linkedin.com" type="text" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Input id="applicantCv" name="uploadcv" placeholder="Upload your CV" type="file" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="applicantMessage">Additional Info</Label>
                                            <textarea className="form-control" id="ApplicantMessage" rows="5"></textarea>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <p>In order to contact you with future jobs that you may be interested in, we need to store your personal data.</p>
                                        <p>If you are happy for us to do so please click the checkbox below.</p>
                                        <Input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <Label className="form-check-label" for="flexCheckChecked"> Allow us to process your personal information</Label>
                                    </Col>
                                    <Col md={12}>
                                        <Link className="btn btn-primary submit" to="/Application">Submit Application</Link>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
export default Application;