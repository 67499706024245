import React, { Component } from 'react';
import { Container, Row, Col} from 'reactstrap';
import './Footer.css';

export class Footer extends Component {

    render() {
        return (
            <footer>
                <div className="copyright">
                    <Container>
                        <Row>
                            <Col className="col-md-7">
                                <div class="d-flex justify-content-md-start">
                                    <p>
                                        2022 &copy; All Rights Reserved.
                                       <a href="#"> Privacy Policy</a> | <a href="#">Terms of Service</a> | <a href="#" target="_blank" title="Developed by Skynet">Developed by Skynet</a><a href="#" title="About Skynet">(About Us)</a>
                                       | <span className='footer-version-spacing'>v5.2.14</span>
                                    </p>
                                </div>
                            </Col>
                            <Col className="col-md-5">
                                <div class="d-flex justify-content-md-end">
                                    <p class="d-flex justify-content-md-end">  
                                        General: <a href="mailto:portal@mnr.krg.org">portal@mnr.krg.org</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                                        Technical: <a href="mailto:support@mnr.krg.org">support@mnr.krg.org</a>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                 </div>
             </footer>
        );
    }
}