import React, { Component } from 'react';
import { Container, Row, Col, Button, CardColumns, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';
import './SimilarJobs.css';

export class SimilarJobs extends Component {

    render() {
        return (
            <div>
                <div id="similarJobsIntro">
                    <Container>
                        <Row>
                            <Col className="col-sm-12">
                                <h3>Similar Jobs</h3>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="similarJobsList">
                    <Container>
                        <Row>
                            <Col className="col-12">
                                <CardColumns>
                                    <Card className="odd">
                                        <CardBody>
                                            <CardImg alt="Card image cap" src={'assets/images/logos/dno.svg'} width="145" />
                                            <CardTitle tag="h3">PP Construction Project Manager
                                                <span className="featured">Featured</span>
                                                <span className="badge green align-middle">Full time</span>
                                                <span className="badge gold align-middle">Urgent</span>
                                            </CardTitle>
                                            <div className="card-text">
                                                <span className="job-family">Projects, Development & Construction Management</span>
                                                <span className="job-location">Tawke PSC</span>
                                                <span className="job-date-added">December 20, 2021</span>
                                                <span className="job-salary">£80,000 - £100,000 / year</span>
                                                <div className="job-links d-inline">
                                                    <span className="job-view">
                                                        <Link to="/jobDetails">View</Link>
                                                    </span>
                                                    <span className="job-shortlist ms-md-5">
                                                        <Link to="/jobDetails">Shortlist</Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>

                                    <Card className="even">
                                        <CardBody>
                                            <CardImg alt="Card image cap" src={'assets/images/logos/danagas.svg'} width="145" />
                                            <CardTitle tag="h3">PP Project Manager
                                                <span className="featured">Featured</span>
                                                <span className="badge green align-middle">Full time</span>
                                                <span className="badge gold align-middle">Urgent</span>
                                            </CardTitle>
                                            <div className="card-text">
                                                <span className="job-family">Projects, Development & Construction Management</span>
                                                <span className="job-location">Tawke PSC</span>
                                                <span className="job-date-added">December 20, 2021</span>
                                                <span className="job-salary">£80,000 - £100,000 / year</span>
                                                <div className="job-links d-inline">
                                                    <span className="job-view">
                                                        <Link to="/jobDetails">View</Link>
                                                    </span>
                                                    <span className="job-shortlist ms-md-5">
                                                        <Link to="/jobDetails">Shortlist</Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>

                                    <Card className="odd">
                                        <CardBody>
                                            <CardImg alt="Card image cap" src={'assets/images/logos/gasprom.svg'} width="145" />
                                            <CardTitle tag="h3">Senior Accountant
                                                <span className="featured">Featured</span>
                                                <span className="badge green align-middle">Full time</span>
                                                <span className="badge gold align-middle">Urgent</span>
                                            </CardTitle>
                                            <div className="card-text">
                                                <span className="job-family">Projects, Development & Construction Management</span>
                                                <span className="job-location">Tawke PSC</span>
                                                <span className="job-date-added">December 20, 2021</span>
                                                <span className="job-salary">£80,000 - £100,000 / year</span>
                                                <div className="job-links d-inline">
                                                    <span className="job-view">
                                                        <Link to="/jobDetails">View</Link>
                                                    </span>
                                                    <span className="job-shortlist ms-md-5">
                                                        <Link to="/jobDetails">Shortlist</Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </CardColumns>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default SimilarJobs